import { createSlice } from '@reduxjs/toolkit';
const getCountry = localStorage.getItem('COUNTRY');
const getIPCOUNTRY = localStorage.getItem('IPCOUNTRY');

const country = (getCountry === 'undefined' || getCountry === "") ? JSON.stringify(getCountry) : (getCountry !== null) ? JSON.parse(getCountry): {};
const IPCountry = (getIPCOUNTRY === 'undefined' || getIPCOUNTRY === "") ? JSON.stringify(getIPCOUNTRY) : (getIPCOUNTRY !== null) ? JSON.parse(getIPCOUNTRY): '';

const initialCountry = {
    country: country,
    IPCountry:IPCountry
}

export const countrySlice = createSlice({
    name: 'country',
    initialState: initialCountry,
    reducers: {
        setCountry(state, action) {
            state.country = action.payload
            localStorage.setItem("COUNTRY", JSON.stringify(action.payload));
        },
        setIpCountry(state,action){
            state.IPCountry = action.payload
            localStorage.setItem("IPCOUNTRY", JSON.stringify(action.payload));
        },
    },
})

export const countryActions = countrySlice.actions;
export default countrySlice.reducer;
