import React, { useState, useMemo } from 'react';
import * as PR from "../../prime-modules/index";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { signup } from '../../services/api';
import * as utils from '../../utils/reuse'
import GoogleLoginComponent from './GoogleLogin';
import AppleLoginComponent from './AppleLogin';
import { Link } from "react-router-dom";
import { handleFirstNameChange, trimFormData } from "../../utils/reuse";
import CustomPhoneNumber from '../CustomPhoneNumber';
import { useToast } from '../../context/ToastContext';
import { affiliateTrackingInfoActions } from '../../store/affiliateTracking';

const Signup = (props) => {
    const {labels, validations, showToast} = useToast()
    const dispatch = useDispatch();
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const headers = useMemo(() => {
        return { afid: affiliateTrackingData };
    }, [affiliateTrackingData]);
    const [checked, setChecked] = useState(false);
    const [phoneValue, setPhoneValue] = useState('');

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: ''
    }

    const validationSchema = () => {
        return Yup.object().shape({
            firstName: Yup.string().trim()
                .required(validations?.VLD0007)
                .min(3, validations?.VLD0008)
                .max(30, validations?.VLD0008),
            email: Yup.string().trim()
                .email(validations?.VLD0006)
                .required(validations?.VLD0005),
            password: Yup.string().trim()
                .required(validations?.VLD0001)
                .min(8, validations?.VLD0002)
                .max(20, validations?.VLD0002)
                .matches(/\d/, validations?.VLD0002)
                .matches(/[A-Za-z]/, validations?.VLD0002)
                .strict(true),
            confirmPassword: Yup.string().trim()
                .required(validations?.VLD0003)
                .when("password", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        validations?.VLD0004
                    )
                }),
        });
    }

    const submitHandler = formData => {
            const trimmedFormData = trimFormData(formData);
            const phone = {
                localPhoneNumber: phoneValue,
                callingCode: trimmedFormData?.callingCode ? trimmedFormData?.callingCode : '',
            }
            const requestObj = { ...trimmedFormData }
            requestObj.phone = phone;
            delete requestObj.callingCode;
            const getResponse = (response) => {
                if (response.result === 'SUCCESS') {
                    dispatch(affiliateTrackingInfoActions.deleteAffiliateTrackingInfo());
                    formik.resetForm();
                    setPhoneValue('');
                    showToast("success",labels?.LBL0282)
                    setTimeout(() => {
                        props.renderFormState(true, "login")
                    }, [3000])
                } else {
                    const error = response.error;
                    const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
                    showToast("error",errorMsg)
                }
            }
            signup(requestObj, headers, dispatch, getResponse);
    }
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: submitHandler
    })

    return (
        <>
            <div className="form-section">
                <div className="text-right form-top-link">
                    <p>{labels?.LBL0087} <Link onClick={() => props.renderFormState(true, 'login')}>{labels?.LBL0068}</Link></p>
                </div>
                <div className="form">
                    <h3>{labels?.LBL0038}</h3>
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                        <div className="input-field">
                            <PR.InputText id="signup-email" name="email" placeholder={labels?.LBL0037} className="w-12"
                                value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                            {formik.errors.email && formik.touched.email
                                ? <div className='error-message'>{formik.errors.email}</div>
                                : ''
                            }
                        </div>
                        <div className="input-field custom-input-field">
                            <div className='grid'>
                                <div className="col-12 lg:col-12 md:col-12">
                                    <PR.InputText id="firstName" name="firstName" placeholder={labels?.LBL0001} className="w-12" value={formik.values.firstName} onChange={(e) => handleFirstNameChange(e, formik)} onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.firstName && formik.touched.firstName
                                        ? <div className='error-message'>{formik.errors.firstName}</div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="input-field">
                            <div className="flex flex-column" >
                                <CustomPhoneNumber formik={formik} setPhoneValue={setPhoneValue} id="phone" />
                            </div>
                        </div>

                        <div className="input-field">
                            <div className='grid'>
                                <div className="col-12 lg:col-6 md:col-6">
                                    <PR.Password feedback={false} id='password' name='password' placeholder={labels?.LBL0071} className="w-12" toggleMask autoComplete="off"
                                        value={formik.values.password} onChange={e => utils.removeWhiteSpacesInPassword(e, formik, "password")} onBlur={formik.handleBlur} />
                                    {formik.errors.password && formik.touched.password
                                        ? <div className='error-message'>{formik.errors.password}</div>
                                        : ''
                                    }
                                </div>
                                <div className="col-12 lg:col-6 md:col-6">
                                    <PR.Password feedback={false} id='confirmPassword' name='confirmPassword' placeholder={labels?.LBL0005} className="w-12" toggleMask autoComplete="off"
                                        value={formik.values.confirmPassword} onChange={e => utils.removeWhiteSpacesInPassword(e, formik, "confirmPassword")} onBlur={formik.handleBlur} />
                                    {formik.errors.confirmPassword && formik.touched.confirmPassword
                                        ? <div className='error-message'>{formik.errors.confirmPassword}</div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex checkbox">
                            <PR.Checkbox inputId="account" name="account" checked={checked} onChange={e => setChecked(e.checked)} />
                            <label htmlFor="account">{labels?.LBL0006}</label>
                        </div>
                        <PR.Button label={labels?.LBL0038} type='submit' disabled={!formik.isValid || !checked} className="login-btn signup-btn mt-3" />
                        <span className="or-span">{labels?.LBL0074}</span>
                        <div className="flex mobile-flex gap-3">
                            <GoogleLoginComponent displayName={labels?.LBL0072} renderFormState={props.renderFormState} type={"signup"} />
                            <AppleLoginComponent displayName={labels?.LBL0073} renderFormState={props.renderFormState} />
                        </div>
                       
                    </form>
                </div>
                <div className="form-footer">
                    <p>{labels?.LBL0078} <Link to="/privacy-policy" onClick={() => props.renderFormState(false, "")}>{labels?.LBL0075}</Link> {labels?.LBL0077} <Link to="/terms-and-conditions" onClick={() => props.renderFormState(false, "")}>{labels?.LBL0076}</Link>.</p>
                </div>
            </div>    
        </>
    )
}

export default Signup;