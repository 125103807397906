import React from "react";
import "../redeem-voucher/RedeemVoucher.scss";
import { useToast } from "../../context/ToastContext";
import { useSelector } from "react-redux";
import { getDayLabel, renderSpeed } from "../../utils/reuse";

const RedeemVoucherBundleDetails = ({ bundleData, setTopupExistingEsim, createOrTopupEsim, topupData }) => {
    const { labels, redeemVoucher } = useToast();
    const isAuth = useSelector(state => state.auth.isLoggedIn);
    return (
        <>
            <div className="vouchers-box bundle-details-section">
                <div className="data-item-content">
                    <div className="flex align-items-center justify-content-between title-description">
                        <h2>{labels.LBL0016}</h2>
                        <h2><span>{bundleData?.description?.split("(")[0].trim() || '-'}</span></h2>
                    </div>
                    <table className="w-12">
                        <tbody>
                            <tr>
                                <td>{labels.LBL0024}</td>
                                <td className="text-right">{bundleData?.dataAmountForDisplay || '-'}</td>
                            </tr>
                            <tr>
                                <td>{labels.LBL0025}</td>
                                <td className="text-right">{bundleData?.duration}{getDayLabel(bundleData?.duration, labels)}</td>
                            </tr>
                            <tr>
                                <td>{labels.LBL0026}</td>
                                <td className="text-right">{bundleData?.displaySpeed || '-'}</td>
                            </tr>
                            <tr>
                                <td>{labels.LBL0027}</td>
                                <td className="text-right">{bundleData?.roamingCountries || '-'}</td>
                            </tr>
                            <tr>
                                <td>{labels.LBL0028}</td>
                                <td className="text-right">
                                    <span class="networkData">
                                        {bundleData?.networks?.map(b => {
                                            return (
                                                <span class="catalogNetworks">{b?.name} ({renderSpeed(b?.speeds)})</span>
                                            )
                                        })}
                                    </span>
                                </td>
                            </tr>
                            <tr className="flex-column">
                                <div className="grid align-items-center justify-content-end">
                                {isAuth && topupData?.esims?.length > 0 &&
                                    <div className="col-12 md:col-6">
                                        <div className="box" onClick={() => {
                                                setTopupExistingEsim(true);
                                        }}>
                                            <div className="card flex align-items-center gap-3">
                                                <div className="icon">
                                                    <i className="pi pi-refresh"></i>
                                                </div>
                                                <div className="text">
                                                    <h2>{redeemVoucher.VOUC010} <br /> {redeemVoucher.VOUC011}</h2>
                                                </div>
                                            </div>
                                            <i className="pi pi-arrow-right"></i>
                                        </div>
                                    </div>  
                                    }
                                    <div className="col-12 md:col-6">
                                        <div className="box" onClick={() => createOrTopupEsim()}>
                                            <div className="card flex align-items-center gap-3">
                                                <div className="icon">
                                                    <i className="pi pi-plus"></i>
                                                </div>
                                                <div className="text">
                                                    <h2>{redeemVoucher.VOUC008} <br /> {redeemVoucher.VOUC009}</h2>
                                                </div>
                                            </div>
                                            <i className="pi pi-arrow-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>);
};

export default RedeemVoucherBundleDetails;
