import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import "./EsimDetails.scss";
import * as PR from "../../../prime-modules/index";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEsimBundles, getUserOrderDetails } from "../../../services/api";
import {
  formatBundleExpiration,
  modifyDate,
  modifyDateFormat,
  renderPrice,
  checkDataSize,
  getBundleDescription,
  getDayLabel
} from "../../../utils/reuse";
import { scrollToBundlesActions } from "../../../store/scrollToBundles";
import { useToast } from "../../../context/ToastContext";

const EsimDetails = ({userId}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast, account, labels} = useToast();
  const userData = useSelector((state) => state.auth.sessionData);
  const iccid = useSelector((state) => state.esim.esimIccid);
  const providerCode = useSelector((state) => state.esim?.providerCode);
  const affiliateTrackingData = useSelector(
    (state) => state.affiliateTracking.affiliateTrackingInfo
  );
  const headers = useMemo(() => {
    return { sessionid: userData.sessionId, afid: affiliateTrackingData };
  }, [userData.sessionId, affiliateTrackingData]);
  const [loading, setLoading] = useState(false);
  const [bundleList, setBundleList] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [viewOrderDialog, setViewOrderDialog] = useState(false);

  const viewBundleDetails = useCallback(() => {
    setLoading(true);
    const request = {
      iccid: iccid,
      userId: userId,
    };
    const getBundleList = (response) => {
      setLoading(false);
      if (response.result === "SUCCESS") {
        const bundleList = response.data ? response.data.sort((a, b) => (new Date(b.createdTs) - new Date(a.createdTs))) : [];

        const bundleListWithStatus = bundleList.map((bundle) => {
          bundle.description = getBundleDescription(bundle);
          if (bundle.state === "active" || bundle.state === "depleted") {
            bundle.bundleStatus = "Active";
          } else if (bundle.state === "queued" || bundle.state === "processing") {
            bundle.bundleStatus = "Queued";
          } else {
            bundle.bundleStatus = "Expired";
          }
          return bundle;
        });

        bundleListWithStatus.sort((a, b) => {
          // Define the sorting order based on bundleStatus
          const order = {
            Active: 0,
            Queued: 1,
            Expired: 2,
          };
          // Compare the bundleStatus of each object
          const statusA = order[a.bundleStatus];
          const statusB = order[b.bundleStatus];
          return statusA - statusB;
        });

        const sections = bundleListWithStatus.reduce(function (acc, obj) {
          if (!acc[obj.bundleStatus]) {
            acc[obj.bundleStatus] = [];
          }
          acc[obj.bundleStatus].push(obj);
          return acc;
        }, {});
        setBundleList(sections);
      } else if (response.result === "FAILED" && response.error) {
        const error = response.error;
        showToast(error.severity, error.errorMsg);
      }
    };
    getEsimBundles(request, headers, dispatch, getBundleList);
  }, [dispatch, headers, iccid, showToast, userId]);

  useEffect(() => {
    let intervalId;
    if (userData.sessionId) {
      viewBundleDetails();
      intervalId = setInterval(() => {
        viewBundleDetails();
      }, 300000); // 5 minutes
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [userData.sessionId, viewBundleDetails])

  const renderProgressBarTemplate = (bundle, sectionName) => {
    const bundleEndTime = bundle.bundleEndTime;
    const bundleStartTime = bundle.bundleStartTime;
    const remainingDays = formatBundleExpiration(bundleStartTime, bundleEndTime, labels).remainingDays;
    return (
      bundle?.bundleStatus  === "Active" ?  <>
        <p>{formatBundleExpiration(bundleStartTime, bundleEndTime, labels).output}
         {` ${remainingDays} ${getDayLabel(remainingDays, labels)}`}
        </p>
        <PR.ProgressBar
          value={formatBundleExpiration(bundleStartTime, bundleEndTime, labels).usedDataPercentage}
        ></PR.ProgressBar>
        {modifyDateFormat(bundleEndTime) !== '' && <p className="text-right">
          {sectionName === "Expired" ? account.ACT0001 : account.ACT0002} <span>{modifyDateFormat(bundleEndTime)}</span>
        </p>}
        </> : <p></p>
    );
  };

  const viewOrderDetails = useCallback(
    (order) => {
      const getOrderDetails = (response) => {
        if (response.result === "SUCCESS") {
          if (response.data && response.data.orderId) {
            setOrderDetails({...response.data, description: order.description});
            setViewOrderDialog(true);
          } else {
            showToast("success", "ORDMSG-03");
          }
        } else {
          const error = response.error;
          showToast(error.severity, error.errorMsg);
        }
      };

      const request = {
        userId: order.userId,
        orderId: order.orderId,
      };

      getUserOrderDetails(request, headers, dispatch, getOrderDetails);
    },
    [dispatch, headers, showToast]
  );

  const deviceDetails = (deviceData) => {
    const  keys = {
      "model": "ACT0039",
      "device": "ACT0040",
      "deviceId": "ACT0041",
      "deviceName": "ACT0042",
      "deviceType": "ACT0043",
      "carrierName": "ACT0044",
      "deviceToken": "ACT0045",
      "mobileBrand": "ACT0046",
    }
    return (
      <span>
        {Object.entries(deviceData).map(([key, value]) => {
          if (value) {
            const keyVal = keys[key];
            return (
              <p key={key}>
                <strong>{account[keyVal]}:</strong> {value}
              </p>
            );
          }
          return null;
        })}
      </span>
    );
  };

  return (
    <>
      <div className="esims-section esim-details-section">
        <div className="grid mt-5">
          <div className="col-12">
            <div className="esim-box iccid-box">
              <label htmlFor="iccid">{account.ACT0023} : {iccid}</label>
            </div>
          </div>
        </div>

        {/* Active Bundles */}

        {loading ? (
          <div className="loading-div">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
            ></i>
          </div>
        ) : (
            Object.keys(bundleList).map((sectionName, index) => {
              return (
                <React.Fragment key={index} >
                  <div className="col-12" >
                    <h3>{sectionName} {account.ACT0038}</h3>
                  </div>
                  <div className="grid">
                    {bundleList[sectionName].length > 0 ? (
                      bundleList[sectionName].map((bundle, index) => {
                        let percentage = bundle.totalData ? (((bundle.totalData - bundle.remainingData) / bundle.totalData) * 100) : 0;
                        const bundleStartYear = new Date(bundle?.bundleStartTime).getFullYear()
                        const bundleEndYear = new Date(bundle?.bundleEndTime).getFullYear()
                        return (
                          <React.Fragment key={index}>
                            <div className="col-12 lg:col-7 md:col-7">
                              <div className="esim-box bundles-list-height">
                                <ul className="user-edit-ul flex align-items-center justify-content-between">
                                  <li className="font-semibold mb-1">
                                    {bundle.description}
                                  </li>
                                  {(bundle?.state === "processing" || (bundle?.state === "expired" && bundle?.totalData === -1)) && <li> <span className="status-box Unknown">Unknown</span></li>}
                                </ul>
                                {
                                  bundle?.state === "processing"
                                    ? (
                                      <div className="text-center">
                                       <h2>{account.ACT0037}</h2>
                                      </div>
                                  ) : bundle?.totalData === -1 && bundleStartYear === 1 && bundleEndYear === 1
                                    ? <h4 className="data-not-available">{account.ACT0124}</h4>
                                    : (
                               <div className="grid">
                                 <div className="col-12 lg:col-6 md:col-6">
                                   {bundle?.isUnlimited === true
                                     ? <div className="card progress-bar"> <span className="data-span"><b>{labels.LBL0167} {account.ACT0123}</b></span> </div>
                                     : (bundle?.totalData === -1
                                       ? <h4 className="data-not-available">{account.ACT0124}</h4>
                                       : <div className="card progress-bar">
                                      <p>
                                        <span>
                                          {checkDataSize((bundle.totalData - bundle.remainingData))}
                                        </span>
                                        {account.ACT0035} {checkDataSize(bundle.totalData)} {account.ACT0036}
                                      </p>
                                      <PR.ProgressBar
                                        value={percentage}
                                      ></PR.ProgressBar>
                                      <p className="text-right">
                                        <span>
                                          {checkDataSize(bundle.remainingData)}
                                        </span>{" "}
                                        {account.ACT0034}
                                      </p>
                                    </div>)}
                                 </div>
                                 <div className="col-12 lg:col-6 md:col-6">
                                   <div className="card progress-bar">
                                     {renderProgressBarTemplate(bundle, sectionName)}
                                   </div>
                                 </div>
                               </div>
                                    )
                                  }
                              </div>
                            </div>
                            {bundle.state !== "processing" && <div className="col-12 lg:col-5 md:col-5 text-center">
                             <div className="grid align-items-center h-full justify-content-end">
                                <div className="col-12 lg:col-8 md:col-8">
                                {bundle?.totalData !== -1 &&
                                  <div className="data-info">
                                    {bundle?.totalData !== -1 && <SemiCircleProgressBar
                                      percentage={bundle?.isUnlimited === true ? 100 : percentage}
                                      diameter={180}
                                      strokeWidth={5}
                                      stroke="#F73759"
                                      background="#ffc8d2"
                                    />}
                                    {bundle?.totalData !== -1 && (bundle?.isUnlimited === true
                                      ? <span className="data-span"><b className="unlimited">{labels.LBL0167}</b></span>
                                      : <span className="data-span">
                                      <b> {checkDataSize(bundle.remainingData)} </b>
                                      {account.ACT0122} {checkDataSize(bundle.totalData)} {account.ACT0033}
                                    </span>)}
                                  </div>}
                                </div>
                                {providerCode !== 2 && <div className="col-12 lg:col-4 md:col-4">
                                  <PR.Button
                                    label={account.ACT0032}
                                    className="view-details-btn"
                                    onClick={() => viewOrderDetails(bundle)}
                                  />
                                </div>}
                              </div>
                            </div>}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      bundleList[sectionName] === 'Queued'
                        ? <div className="col-12">
                          <div className="esim-box">
                            <div className="esim-not-installed">
                              <i className="pi pi-database"></i>
                              <p>{account.ACT0028} {sectionName} {account.ACT0029}</p>
                              <p className="font-weight-light">
                                {sectionName} {account.ACT0031}
                              </p>
                              <ul className="buttons-ul flex align-items-center justify-content-center">
                                <li>
                                  <PR.Button
                                    label={account.ACT0030}
                                    icon="pi pi-credit-card"
                                    className="choose-plans-button p-button-rounded"
                                    onClick={() => navigate("/")}
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        : <div className="col-12">
                          <div className="esim-box">
                            <div className="esim-not-installed">
                              <i className="pi pi-database"></i>
                              <p>{account.ACT0028} {sectionName} {account.ACT0029}</p>
                            </div>
                          </div>
                        </div>
                  )}
                </div>
              </React.Fragment>
            );
          })
        )}

        {/* useful links */}
        {!loading &&
          <>
            <h3>{account.ACT0027}</h3>
            <div className="grid">
              <div className="col-12">
                <ul className="useful-buttons flex align-items-center gap-4">
                  <li>
                    <PR.Button
                      label={account.ACT0026}
                      icon="pi pi-plus-circle"
                      className="choose-plans-button p-button-rounded"
                      onClick={() => {
                        navigate("/")
                        dispatch(scrollToBundlesActions.setScrollToBundles(true));
                      }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </>
        }
      </div>

      <PR.Dialog
        className="order-details-dialog"
        header={account.ACT0025}
        blockScroll={true}
        draggable={false}
        resizable={false}
        style={{ width: "65%" }}
        visible={viewOrderDialog}
        onHide={() => setViewOrderDialog(false)}
      >
        <ul className="flex gap-5 mb-2 order-details-ul justify-content-between">
          <li>
            <b>{account.ACT0024}: </b> {orderDetails.orderId}
          </li>
          <li>
            <b>{account.ACT0023}: </b> {orderDetails.iccid}
          </li>
          <li>
            <b>{account.ACT0022}: </b>
            <span className={"status-box " + orderDetails.orderStatus}>
              {orderDetails.orderStatus}
            </span>
          </li>
          <li>
            <b>{account.ACT0021}: </b> {modifyDate(orderDetails.createdTs)}
          </li>
        </ul>
        <div className="plan-details-box">
          {orderDetails?.dataPlans?.map((plan, index) => (
            <ul className="flex align-items-center justify-content-between" key={index}>
              <li>
                <h3>{orderDetails?.description}</h3>
                <span className="EsimCompatibleDetails">
                  {orderDetails.isEsimCompatible ? (
                    <span className="esimCompatible">
                      {" "}
                      {account.ACT0020}{" "}
                    </span>
                  ) : (
                    <span className="esimNonCompatible">
                      {" "}
                      {account.ACT0019}{" "}
                    </span>
                  )}
                  {Object.keys(orderDetails.deviceInfo).length !== 0 && (
                    <>
                      <PR.Tooltip target=".deviceDetails" position="bottom">
                        {deviceDetails(orderDetails.deviceInfo)}
                      </PR.Tooltip>
                      <i className="deviceDetails pi pi-info-circle info-btn"></i>
                    </>
                  )}
                </span>
              </li>
              <li>
                {orderDetails.promoCode && (orderDetails.priceBeforeDiscount !== orderDetails.totalOrderAmount) &&
                  <>
                    <PR.Tooltip target=".promoCodeData" position="bottom">
                      <span className="promoCode">{account.ACT0018}: <br /> {orderDetails.promoCode}</span>
                    </PR.Tooltip>
                    <i className="promoCodeData pi pi-info-circle mr-2"></i>
                    <span className="discount"><s>{renderPrice(orderDetails.priceBeforeDiscount)}</s></span>
                    <span className="arrow mr-2 ml-2">&#x2192;</span>
                  </>
                }
                <span className="price">{renderPrice(orderDetails.totalOrderAmount)}</span>
              </li>
            </ul>
          ))}
        </div>
        <div className="grid details-section">
          <div className="col-12 lg:col-4 md:col-12">
            <div className="details-box">
              <h3>{account.ACT0017}</h3>
              <ul>
                <li>
                  <b>{account.ACT0015}: </b> {orderDetails?.firstName}{" "}
                  {orderDetails?.lastName}
                </li>
                <li>
                  <b>{account.ACT0014}: </b> {orderDetails?.email}
                </li>
                <li>
                  <b>{account.ACT0013}: </b> {orderDetails?.phone?.callingCode + ' ' + orderDetails?.phone?.localPhoneNumber}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-12">
            <div className="details-box">
              <h3>{account.ACT0016}</h3>
              <ul>
                <li>
                  <b>{account.ACT0015}: </b>
                  {orderDetails.contactInfo?.firstName}{" "}
                  {orderDetails.contactInfo?.lastName}
                </li>
                <li>
                  <b>{account.ACT0014}: </b> {orderDetails.contactInfo?.email}
                </li>
                <li>
                  <b>{account.ACT0013}: </b>{" "}
                  {orderDetails.contactInfo?.phone
                    ? orderDetails.contactInfo?.phone?.callingCode + ' ' + orderDetails.contactInfo?.phone?.localPhoneNumber
                    : "-"}
                </li>
                {orderDetails.paymentGateway === "Stripe" && (
                  <>
                    <li>
                      <b>{account.ACT0012}: </b>{" "}
                      {orderDetails.billingAddress?.address}
                    </li>
                    <li>
                      <b>{account.ACT0011}: </b> {orderDetails.billingAddress?.street}
                    </li>
                    <li>
                      <b>{account.ACT0010}: </b> {orderDetails.billingAddress?.city}
                    </li>
                    <li>
                      <b>{account.ACT0009}: </b> {orderDetails.billingAddress?.postCode}
                    </li>
                    <li>
                      <b>{account.ACT0008}: </b> {orderDetails.billingAddress?.country}
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-12">
            <div className="details-box">
              <h3>{account.ACT0007}</h3>
              <ul>
                <li>
                  <b>{account.ACT0006}: </b>{" "}
                  {orderDetails.paymentGateway === "Stripe"
                    ? account.ACT0005
                    : orderDetails.paymentGateway}
                </li>
                <li>
                  <b>{account.ACT0004}: </b> {orderDetails?.transactionId}
                </li>
                <li>
                  <b>{account.ACT0003}: </b>{" "}
                  <span
                    className={"status-box " + orderDetails.transactionStatus}
                  >
                    {orderDetails?.transactionStatus}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </PR.Dialog>
    </>
  );
};

export default EsimDetails;
